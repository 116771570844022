//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Search from '@/components/Search'
export default {
    components: {Search},
    data() {
        return {
			//uniacid:0,
            uniacid:window.localStorage.getItem('uniacid'),
            //uniacid:17,
            orderData: null,
            paymentList: [],
            pay_type: '',
            dialogVisible: false,
            dialogPaypalVisible: false,
            currency: window.localStorage.getItem('currency'),
            paypalData:null,
            ordersn: ''
        };
    },
    created() {
		if(this.$route.query.order_id){
			 this.getOrderInfo(this.$route.query.order_id)
		}else{
			 this.getOrderInfo(this.$route.params.order)
		}
        var url = window.location.href;
        var dz_url = url.split("#")[0];
        var www_dz_url = dz_url.split("/")[2];
        var centerdomain= www_dz_url.split(".");
        if(centerdomain[0]=='ace' || this.uniacid == 19){
            this.yedpay=true;

            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=AVBI7idWVEcagwi3IvKwNTwAYFPcfguSr6LePapIWdZNuzAGZsYdj3IJB-m5eD-pK8YSnKeJOB5wTArv&currency=HKD");
            document.head.appendChild(tag);
        }
        else if(centerdomain[0]=='uf' || this.uniacid == 20){
            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=AU7iIVdB-LPIZ88IWIfG7fBGrzIlrdUZr5DM2--RGSM5XdEKjmgljhRnRJHgOnUE92XgdIky86lh5SUe&currency=HKD");
            document.head.appendChild(tag);
        }

        
    },
    methods: {
        async getOrderInfo(order_sn) {
            const res = await this.$http.get('/order/detail', {order_sn})
            if (res.code !== 0) {
                return this.$message.error(res.message); 
            }
            let data = res.data
            this.orderData = data
            this.ordersn =this.orderData.basic.data.field.order_sn
        },
        //選擇支付方式
        async getPaymentList() {
			
			 this.uniacid =window.localStorage.getItem('uniacid');
             //this.uniacid = 17;
            const res = await this.$http.get('/pay', {
                order_sn: this.orderData.basic.data.field.order_sn,v:5,uniacid:this.uniacid
            })
            if (res.code !== 0) {
                return this.$message.error(res.message);
            }
            this.paymentList = res.data.list
            this.dialogVisible = true
        },

        async pay() {
			console.log('paytype',this.pay_type);
            if(!this.pay_type){
                return this.$message.error('請選擇支付方式')
            }
			let gourl1="https://w7.khshop.online/app/index.php?i="+this.uniacid+"&c=entry&m=yumi_jinxiaocun&do=api_payasia_pc_wechat&pay_type="+this.pay_type
			    gourl1+="&amount="+this.orderData.basic.data.field.order_amount
			    gourl1+="&order_sn="+this.orderData.basic.data.field.order_sn
			console.log('payasia....');
			
            if(this.pay_type=='yedpay'){
                let yedpayurl="https://w7.khshop.online/app/index.php?i="+this.uniacid+"&c=entry&m=yumi_jinxiaocun&do=api_yedpay";
                yedpayurl+="&order_sn="+this.orderData.basic.data.field.order_sn
				console.log('yedpay');
				window.location.href= yedpayurl
				return 
			}
			if(this.pay_type=='payasia'){
				console.log('payasia....');
				window.location.href= gourl1
				return 
			}
			if(this.pay_type=='paypal'){
				let gourl="https://w7.khshop.online/app/index.php?i="+this.uniacid+"&c=entry&m=yumi_jinxiaocun&do=paypal2"
				    gourl+="&price="+this.orderData.basic.data.field.order_amount
				    gourl+="&tcid="+this.orderData.basic.data.field.order_sn
				console.log('paypal....');
                
                
                //this.dialogPaypalVisible=true;
				//window.location.href= gourl
                this.showPaypal(gourl, this.uniacid, this.ordersn);

                /*
                paypal.Buttons({
                // Order is created on the server and the order id is returned
                createOrder() {
                    return fetch(gourl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        // use the "body" param to optionally pass additional order information
                        // like product skus and quantities
                        body: JSON.stringify({
                            cart: [
                                    {
                                    sku: "YOUR_PRODUCT_STOCK_KEEPING_UNIT",
                                    quantity: "YOUR_PRODUCT_QUANTITY",
                                    },
                                ],
                            }),
                        })
                        .then((response) => {
                            response.json();
                            console.log('responseeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
                            console.log(response.json());
                        })
                        .then((order) => {
                            order.id
                        });
                    }
                }).render('#paypal-button-container');*/
				return 
			}
			if(this.pay_type=='payasiazfb'){
				console.log('payasia....');
				window.location.href= gourl1
				return  
			}		
			if(this.pay_type=='payasiawechat'){
			
				window.location.href= gourl1
				return 
			}
            const res = await this.$http.post('/pay/'+this.pay_type, {
                order_sn: this.orderData.basic.data.field.order_sn
            })
            if(res.code == 0){
                 this.$message({message: res.message, type: 'success'})
                let timer = setTimeout(() => {
                    this.$router.push({name: 'User'})
                }, 500);
            }else{
                  return this.$message.error(res.message)
            }
        },
        showPaypal(gourl, uniacid, ordersn) {
            paypal.Buttons({
                    style: {
                        layout: 'horizontal'
                    },
                    createOrder: function(data, actions) {
                        return fetch(gourl, {
                            method: 'post'
                        }).then(function(res) {
                            return res.json();
                        }).then(function(orderData) {
                            console.log('orderDataaaaaaaaaaaaaaaaaa')
                            console.log(orderData)
                            //this.paypalData = orderData;
                            return orderData.id;
                        });
                    },
                    onApprove(data) {
                        console.log('dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
                        console.log(data)
                        console.log('data.orderID: ' + data.orderID)
                        let gourl2="https://w7.khshop.online/app/index.php?i="+uniacid+"&c=entry&m=yumi_jinxiaocun&do=paypal2_capture"
				        gourl2+="&tcid="+ordersn
                        gourl2+="&orderid="+data.orderID
                        console.log('gourl2: ' + gourl2)
                        return fetch(gourl2, {
                            method: "post",
                            mode: "no-cors",
                        })
                        .then((response) => {
                            console.log('responseeeeeee')
                            console.log(response)
                            response.json()
                        })
                        .then((orderData) => {
                            console.log('orderrrrrrrrrrrrrrrrrrrrrrr')
                            console.log(orderData)

                            let backurl = "/#/user?type=0";
                            window.location.href= backurl;


                            // Successful capture! For dev/demo purposes:
                            //console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                            //const transaction = orderData.purchase_units[0].payments.captures[0];
                            //alert(`Transaction ${transaction.status}: ${transaction.id}\n\nSee console for all available details`);
                            // When ready to go live, remove the alert and show a success message within this page. For example:
                            // const element = document.getElementById('paypal-button-container');
                            // element.innerHTML = '<h3>Thank you for your payment!</h3>';
                            // Or go to another URL:  window.location.href = 'thank_you.html';
                        });
                    }
                }).render('#paypal-button-container');
        },
        handleClose() {
			this.dialogVisible=false;
            this.dialogPaypalVisible=false;
        }
    }
};
